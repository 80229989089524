import { observer } from 'mobx-react';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { configure } from 'mobx';
import utc from 'dayjs/plugin/utc';
import qs from 'qs';
import { BrowserRouter } from 'react-router-dom';
import { AuthStoreContext } from './context/authContextType';
import AuthView from './auth';
import Loading from './components/Elements/Loading';
import { LangStoreContext } from './context/langContextType';
import 'dayjs/locale/ru';
import Lang from './utils/lang/lang';
import './utils/dayjsConfig';
import { useLanguageStore } from './stores/langStore';
import { HintRefsContext } from './context/guideRefsContextType';
import { makeNumbersArray } from './utils/numbers';
import { appSettingsStore } from './stores/traforySettingStore';
import Navigation from './components/Navigation';

// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js';

configure({
  enforceActions: 'never',
});

export const useAuthStore = () => useContext(AuthStoreContext);

export const useLocale = () => useContext(LangStoreContext);

dayjs.extend(utc);
dayjs.locale(Lang.locale);

const App = observer(() => {
  const { langStore } = useLanguageStore();
  const { authStore } = useAuthStore();
  const { token, tenant, __tenant }: any = qs.parse(location.search, { ignoreQueryPrefix: true });

  useLayoutEffect(() => {
    // TODO: rewrite somehow this sh*t

    if (__tenant) {
      localStorage.setItem('tenantId', __tenant);
      authStore.checkTenantById(__tenant);
    }
  }, []);

  useEffect(() => {
    appSettingsStore.updateStyles();
  }, [token, tenant]);

  useEffect(() => {
    if ((tenant || token) && (localStorage.getItem('tenancyName') || localStorage.getItem('tenantId') || localStorage.getItem('token'))) {
      authStore.logout();
    }
  }, [tenant, token]);

  return (
    <AuthStoreContext.Provider value={{ authStore }}>
      <LangStoreContext.Provider value={{ lang: langStore.localeData }}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: appSettingsStore.data?.defaultFont,
            },
          }}
          locale={authStore.locale}
        >
          <HintRefsContext.Provider
            value={{
              guideRefs: makeNumbersArray(11).map((i) => ({
                id: i,
                ref: useRef<any>(null),
              })),
            }}
          >
            <BrowserRouter>
              <AppState />
            </BrowserRouter>
          </HintRefsContext.Provider>
        </ConfigProvider>
      </LangStoreContext.Provider>
    </AuthStoreContext.Provider>
  );
});

export default App;

const AppState = observer(() => {
  const { langStore } = useLanguageStore();
  const { authStore } = useAuthStore();

  useEffect(() => {
    const getConfig = async () => {
      await authStore.getConfig();
      await langStore.getLocale();
    };
    getConfig();
  }, []);

  if (!authStore.app || !langStore.localization || authStore.isGlobalLoading) {
    return <Loading />;
  }

  return authStore.isLogged ? <Navigation /> : <AuthView />;
});
