export const HcmLocaleKeys = (T: (e: string) => string) => ({
  jobProfiles: T('JobProfiles_UI'),
  competencies: T('Competencies_UI'),
  competenciesInThisCategory: T('CompetenciesInThisCategory_UI'),
  category: T('Category_UI'),
  enterCategoryName: T('EnterCategoryName_UI'),
  chooseCompetencyForThisCategory: T('ChooseCompetencyForThisCategory_UI'),
  addCompetency: T('AddCompetency_UI'),
  competence: T('Competence_UI'),
  dataSuccessfulltUpdated: T('DataSuccessfullyUpdated_UI'),
  newCategory: T('NewCategory_UI'),
  edit: T('Edit_UI'),
  delete: T('Delete_UI'),
  areYouSure: T('AreYouSure_UI'),
  newCompetency: T('NewCompetency_UI'),
  competencyQuality: T('CompetencyQuality_UI'),
  enterTheNameOfCompetence: T('EnterTheNameOfCompetence_UI'),
  componentOfCompetence: T('ComponentOfCompetence_UI'),
  enterTheComponentOfCompetence: T('EnterTheComponentOfCompetence_UI'),
  levelDescription: T('LevelDescription_UI'),
  enterLevelDescription: T('EnterLevelDescription_UI'),
  addComponentOfCompetence: T('AddComponentOfCompetence_UI'),
  cancel: T('Cancel_UI'),
  save: T('Save_UI'),
  competenceComponent: T('CompetenceComponent_UI'),
  level: T('Level_UI'),
  categories: T('Categories_UI'),
  list: T('List_UI'),
  ratingScale: T('RatingScale_UI'),
  more: T('More_UI'),
  fiveLevelScale: T('FiveLevelScale_UI'),
  description: T('Description_UI'),
  definitionOfCompetence: T('DefinitionOfCompetence_UI'),
  competenceLevel: T('CompetenceLevel_UI'),
  chooseCompetencyScale: T('ChooseCompetencyScale_UI'),
  averageScore: T('AverageScore_UI'),
  categoryAverageScore: T('CategoryAverageScore_UI'),
  categoryScore: T('CategoryScore_UI'),
  expectedLevel: T('ExpectedLevel_UI'),
  editCompetence: T('EditCompetence_UI'),
  certificateRequirements: T('CertificateRequirements_UI'),
  functionalResposibilities: T('FunctionalResposibilities_UI'),
  competenceProfile: T('CompetenceProfile_UI'),
  addCategory: T('AddCategory_UI'),
  idealJobProfile: T('IdealJobProfile_UI'),
  update: T('Update_UI'),
  participants: T('Participants_UI'),
  materialsForEvaluation: T('MaterialsForEvaluation_UI'),
  results: T('Results_UI'),
  employeePotentialAssessment: T('EmployeePotentialAssessment_UI'),
  basicInformation: T('BasicInformation_UI'),
  status: T('Status_UI'),
  dateEnds: T('DateEnds_UI'),
  dateStart: T('DateStart_UI'),
  evaluationObject: T('EvaluationObject_UI'),
  competencyOwnerObject: T('CompetencyOwnerObject_UI'),
  add: T('Add_UI'),
  addParticipant: T('AddParticipant_UI'),
  employees: T('Employees_UI'),
  managers: T('Managers_UI'),
  company: T('Company_UI'),
  competenceAssessment: T('CompetenceAssessment_UI'),
  evaluationMethods: T('EvaluationMethods_UI'),
  jobName: T('JobName_UI'),
  title: T('Title_UI'),
  evaluationMethod: T('EvaluationMethod_UI'),
  selectEvaluationMethod: T('SelectEvaluationMethod_UI'),
  fillingInstructions: T('FillingInstructions_UI'),
  selectCompetence: T('SelectCompetence_UI'),
  selectLevel: T('SelectLevel_UI'),
  selectCategory: T('SelectCategory_UI'),
  next: T('Next_UI'),
  jobProfileV1: T('JobProfileV1_UI'),
  jobProfileV2: T('JobProfileV2_UI'),
  jobProfileV3: T('JobProfileV3_UI'),
  jobProfileV4: T('JobProfileV4_UI'),
  jobProfileV5: T('JobProfileV5_UI'),
  jobProfileV6: T('JobProfileV6_UI'),
  jobProfileV7: T('JobProfileV7_UI'),
  jobProfileV8: T('JobProfileV8_UI'),
  jobProfileV9: T('JobProfileV9_UI'),
  jobProfileV10: T('JobProfileV10_UI'),
  jobProfileV11: T('JobProfileV11_UI'),
  jobProfileV12: T('JobProfileV12_UI'),
  jobProfileV13: T('JobProfileV13_UI'),
  updateData: T('UpdateData_UI'),
  deleteAllData: T('DeleteAllData_UI'),
  materials: T('Materials_UI'),
  addMaterial: T('AddMaterial_UI'),
  questionnaire: T('Questionnaire_UI'),
  preview: T('Preview_UI'),
  conductingAssessment: T('ConductingAssessment_UI'),
  compLevel1: T('CompLevel1_UI'),
  compLevel2: T('CompLevel2_UI'),
  compLevel3: T('CompLevel3_UI'),
  compLevel4: T('CompLevel4_UI'),
  compLevel5: T('CompLevel5_UI'),
  employee: T('Employee_UI'),
  evaluation: T('Evaluation_UI'),
  evaluateColleague: T('EvaluateColleague_UI'),
  reject: T('Reject_UI'),
  score: T('Score_UI'),
  colleageueEvaluation: T('ColleageueEvaluation_UI'),
  personalCompetenceEvaluation: T('PersonalCompetenceEvaluation_UI'),
  talentEvaluation: T('TalentEvaluation_UI'),
  colleaugeAssessAndPersonal: T('ColleaugeAssessAndPersonal_UI'),
  matrixPotential: T('MatrixPotential_UI'),
  materialsForDevPlan: T('MaterialsForDevPlan_UI'),
  selectCompany: T('SelectCompany_UI'),
  fillRequiredFields: T('FillRequiredFields_UI'), // Заполните обязательные поля
  pdpPurpose: T('PdpPurpose_UI'),
  producer: T('Producer_UI'),
  mentor: T('Mentor_UI'),
  result: T('Result_UI'),
  personalDevelopmentPlan: T('PersonalDevelopmentPlan_UI'),
  goalsAndTasks: T('GoalsAndTasks_UI'),
  competenceFinalAssessment: T('CompetenceFinalAssessment_UI'),
  goToAssessment: T('GoToAssessment_UI'),
  addEvaluation: T('AddEvaluation_UI'),
  evaluationName: T('EvaluationName_UI'),
  completeSurveyConfirmation: T('CompleteSurveyConfirmation_UI'),
  currentPartWillBeAvailableAfterEvaluationStart: T('CurrentPartWillBeAvailableAfterEvaluationStart_UI'),
  currentPartWillBeAvailableAfterEvaluationFinished: T('CurrentPartWillBeAvailableAfterEvaluationFinished_UI'),
  finalScore: T('FinalScore_UI'),
  applyForAllParticipants: T('ApplyForAllParticipants_UI'),
  dupliacteMaterialsOnEmail: T('DupliacteMaterialsOnEmail_UI'),
  choiceOfCompetence: T('ChoiceOfCompetence_UI'),
  selectTheCompetency: T('SelectTheCompetency_UI'),
  materialSelection: T('MaterialSelection_UI'),
  evaulationMaterial: T('EvaluationMaterial_UI'),
  runAssessment: T('RunAssessment_UI'),
  createEvaluationPlaceholder: T('CreateEvaluationPlaceholder_UI'),
  view: T('View_UI'),
  evaluate: T('Evaluate_UI'),
  myEvaluations: T('MyEvaluations_UI'),
  reminding: T('Reminding_UI'),
  colleagues: T('Colleagues_UI'),
  responsibleForEvaluation: T('ResponsibleForEvaluation_UI'),
  evaluationResult: T('EvaluationResult_UI'),
  manager: T('Manager_UI'),
  generate: T('Generate_UI'),
  dataSaved: T('DataSaved_UI'),
  enterEvaluationName: T('EnterEvaluationName_UI'),
  allCompetencesAlreadySelected: T('AllCompetencesAlreadySelected_UI'),
  assessmentParticipantsComments: T('assessmentParticipantsComments_UI'),
  evaluationResults: T('evaluationResults_UI'),
  draft: T('Draft_UI'),
  inPlan: T('InPlan_UI'),
  inWork: T('InWork_UI'),
  completed: T('Completed_UI'),
  archived: T('Archived_UI'),
  deleted: T('Deleted_UI'),
  compLevel1Name: T('CompLevel1_Name_UI'),
  compLevel2Name: T('CompLevel2_Name_UI'),
  compLevel3Name: T('CompLevel3_Name_UI'),
  compLevel4Name: T('CompLevel4_Name_UI'),
  compLevel5Name: T('CompLevel5_Name_UI'),
  compLevel1About: T('CompLevel1_About_UI'),
  compLevel2About: T('CompLevel2_About_UI'),
  compLevel3About: T('CompLevel3_About_UI'),
  compLevel4About: T('CompLevel4_About_UI'),
  compLevel5About: T('CompLevel5_About_UI'),
  evaluatedParticipant: T('EvaluatedParticipant_UI'),
  evaluatorParticipant: T('EvaluatorParticipant_UI'),
  onlyOneParicipantAllowed: T('OnlyOneParicipantAllowed_UI'),
  competeceIsInUse: T('CompetenceInUse_UI'),
  generalAvarageScore: T('GeneralAvarageScore_UI'),
  calulationResultScore: T('CalulationResultScore_UI'),
  evaluationFormula: T('EvaluationFormula_UI'),
  cantDeleteEvaluationParticipantAlert: T('CantDeleteEvaluationParticipantAlert_UI'),
  responsibleForEvaluationCantBeDeleted: T('ResponsibleForEvaluationCantBeDeleted_UI'),
  goals: T('Goals_UI'),
  resultativity: T('Resultativity_UI'),
  evaluationResultativity: T('EvaluationResultativity_UI'),
  complianceWithThePositionProfile: T('ComplianceWithThePositionProfile_UI'),
  complianceWithTheCompetencyProfile: T('ComplianceWithTheCompetencyProfile_UI'),
  dateOfNextAssessment: T('DateOfNextAssessment_UI'),
  evaluationAverageScore: T('EvaluationAverageScore_UI'),
  materialType: T('MaterialType_UI'),
  editMaterial: T('EditMaterial_UI'),
  addLevel: T('AddLevel_UI'),
  competencyLevel: T('CompetencyLevel_UI'),
  enterShortMaterialDescription: T('EnterShortMaterialDescription_UI'),
  enterMaterialName: T('EnterMaterialName_UI'),
  file: T('File_UI'),
  attachFileWithType: T('AttachFileWithType_UI'),
  provideTextLinkToMaterial: T('ProvideTextLinkToMaterial_UI'),
  selectCourse: T('SelectCourse_UI'),
  link: T('Link_UI'),
  course: T('Course_UI'),
  materialsForEvaluationCompetence: T('MaterialsForEvaluationCompetence_UI'),
  resultativityEvaluation: T('ResultativityEvaluation_UI'),
  evaluationProcess: T('EvaluationProcess_UI'),
  putFinalEvaluationMark: T('PutFinalEvaluationMark_UI'),
  workExperience: T('WorkExperience_UI'),
  workPlaceOrganization: T('WorkPlaceOrganization_UI'),
  workRelationships: T('WorkRelationships_UI'),
  comments: T('Comments_UI'),
  notProvided: T('NotProvided_UI'),
  minimumCountOfCompetanceComponents: T('MinimumCountOfCompetanceComponents_UI'),
  provideAllEvaluationGrades: T('provideAllEvaluationGrades_UI'),
  matrixResult: T('MatrixResult_UI'),
  deleteData: T('DeleteData_UI'),
  jobProfile: T('JobProfile_UI'),
  createJobProfile: T('CreateJobProfile_UI'),
  educationRequirement: T('EducationRequirement_UI'),
  jobExperienceRequirement: T('JobExperienceRequirement_UI'),
  personalityRequirement: T('PersonalityRequirement_UI'),
  profileVersion: T('ProfileVersion_UI'),
  goal: T('Goal_UI'),
  task: T('Task_UI'),
  tasks: T('Tasks_UI'),
  weight: T('Weight_UI'),
  changeLevel: T('ChangeLevel_UI'),
  automaticallyFillWeight: T('AutomaticallyFillWeight_UI'),
  automatical: T('Automatical_UI'),
  newGoal: T('NewGoal_UI'),

  low: T('Low_UI'),
  middle: T('Middle_UI'),
  high: T('High_UI'),
  matrixEmployees: T('MatrixEmployees_UI'),
  radarDiagram: T('RadarDiagram_UI'),
  competenceEvaluationMethods: T('CompetenceEvaluationMethods_UI'),
});
