import { CSSProperties } from 'react';
import Styles from '../utils/styles';

type Theme = 'primary' | 'secondary'; // Первичный или вторичный цвет

interface Props {
  src: string;
  color?: string;
  scale?: string;
  isDefault?: boolean; // Применяет картинку без доп. стилей
  type?: Theme;
  onClick?: () => void;
  style?: CSSProperties
}

// Компонент для отображения картинок SVG
export default function IconSVG(props: Props) {
  const press = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const color = () => {
    if (props.color) {
      return props.color;
    }
    if (props.type) {
      return props.type === 'primary' ? Styles.primary : Styles.secondary;
    }
    return Styles.night700;
  };

  if (props.isDefault) {
    return <img alt={props.src} src={props.src} />;
  }
  return (
    <div style={{ width: 24, transform: `scale(${props.scale ?? 1})`, ...(props.style || {}) }} onClick={press}>
      <div style={{ height: 24, width: 24 }}>
        <div
          style={{
            height: 24,
            width: 24,
            backgroundColor: color(),
            WebkitMask: `url(${props.src}) no-repeat center`,
            mask: `url(${props.src}) no-repeat center`,
          }}
        />
      </div>
    </div>
  );
}
