/* eslint-disable new-cap */
import { makeAutoObservable } from 'mobx';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  GetAdditionalData,
  GetCertificateOutput,
  GetPagedPersonManagementListDto,
  GetPersonManagementOutput,
  IFamilyMember,
  IGeneralEducationList,
  SetPersonInfo,
  UpdatePersonAccountDto,
  UpdatePersonManagementDto,
} from '../../services/personManagementService/dto';
import personManagementService from '../../services/personManagementService';
import userManagementService from '../../services/userManagementService';
import identityService from '../../services/usersService';
import { RoleCoreHr } from '../../models/Role_CoreHr';
import { CreateUserAccountDto } from '../../services/userManagementService/dto';
import { PersonProfile } from '../../../../models/PersonProfile';
import http from '../../../../services/httpService';
import { FileManagerUploadOutput } from '../../../../services/FileManager/dto';
import { EmployeeIntroType } from '../../../../enum/PersonManagement';
import authMethodsService from '../../services/authMethodsService';
import { AuthMethod } from '../../models/AuthMethod';
import usersService from '../../services/usersService';

export default class Index {
  data: GetPersonManagementOutput[] = [];

  totalCount = 0;

  user?: GetPersonManagementOutput;

  profile?: PersonProfile;

  additionalInformation?: GetAdditionalData;

  availableRole?: RoleCoreHr[];

  userRoles?: RoleCoreHr[];

  selectedUser?: GetPersonManagementOutput;

  isLoading = false;

  certificatePdf?: any;

  certificateJson?: GetCertificateOutput;

  certificateJsonDownload?: GetCertificateOutput;

  authMethods?: AuthMethod[];

  accessRight?: RoleCoreHr[];

  familyMembers?: IFamilyMember[];

  generalEducation?: IGeneralEducationList[];

  get hasMore() {
    return this.totalCount > this.data.length;
  }

  createALotEmployeesFromUsers = async (arrayOfUserIds: string[]) => {
    const result = await userManagementService.createALotEmployeesFromUsers(arrayOfUserIds);

    return result;
  };

  getAccessRigthEmployeesFormUser = async () => {
    this.accessRight = await usersService.getAvailableRoles();
  };

  async getPagedList(e: GetPagedPersonManagementListDto) {
    const result = await personManagementService.getPagedList(e);
    this.totalCount = result.totalCount;
    return result.items;
  }

  async searchByName(e: string) {
    const result = await personManagementService.getPagedList({ FilterText: e, MaxResultCount: 1000 });
    this.data = result.items;
    this.totalCount = 0;
  }

  async readById(id?: string) {
    this.isLoading = true;
    this.user = await personManagementService.getById(id);
    this.isLoading = false;
  }

  async getPersonProfile(id?: string) {
    this.isLoading = true;
    this.profile = await personManagementService.getPersonProfile(id);
    this.isLoading = false;
  }

  public getAdditionalInformation = async (personId: string) => {
    try {
      this.isLoading = true;
      this.additionalInformation = await personManagementService.getAdditionalData(personId);
    } catch (error) {
      console.error('Error personManagmentStore', error);
    }
  };

  getGeneralEducationList = async (personId: string) => {
    try {
      this.isLoading = true;

      const result = await personManagementService.getGeneralEducationList(personId);
      this.generalEducation = result;
    } catch (error: any) {
      console.log('PersonManagementStore.GeneralEducationList', error);
    } finally {
      this.isLoading = false;
    }
  };

  async createAccount(e: CreateUserAccountDto) {
    this.isLoading = true;
    try {
      await userManagementService.createUserAccount(e);
      this.totalCount += 1;
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  async updatePerson(e: UpdatePersonManagementDto) {
    this.isLoading = true;
    try {
      await personManagementService.updatePerson(e);
      await this.refreshSelectedUser(e.id);
    } finally {
      this.user = undefined;
      this.isLoading = false;
    }
  }

  async updatePersonProfile(dto: UpdatePersonManagementDto) {
    this.isLoading = true;
    try {
      await personManagementService.updatePersonProfile(dto);
      this.getPersonProfile(dto.id);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePersonProfileAbout(dto: SetPersonInfo, id?: string) {
    this.isLoading = true;
    try {
      await personManagementService.setAvatar(dto, id);
      this.getPersonProfile(id);
    } finally {
      this.isLoading = false;
    }
  }

  getFamilyMemberStoreList = async (personId: string) => {
    try {
      this.isLoading = true;

      const result = await personManagementService.getFamilyMemberList(personId);
      this.familyMembers = result;
    } catch (error: any) {
      console.error('personManagementStore.getFamilyMemberStoreList', error);
    } finally {
      this.isLoading = false;
    }
  };

  async setPersonAvatar(item: FormData, id?: string) {
    this.isLoading = true;
    try {
      const result = await http.post('/api/trafory-file-management/file/avatar', item, {
        timeout: 2500000,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${crypto.randomUUID()}`,
        },
      });
      const resultAvatar = result.data as FileManagerUploadOutput;
      await personManagementService.setAvatar({ avatar: resultAvatar.fileInfo.id }, id);
      this.getPersonProfile(id);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePersonAccount(dto: UpdatePersonAccountDto, personId?: string) {
    this.isLoading = true;
    try {
      await personManagementService.updatePersonAccount(dto);
      this.getPersonProfile(personId);
    } finally {
      this.isLoading = false;
    }
  }

  async createEmployeeCard(id: string, values?: any) {
    this.isLoading = true;
    try {
      await userManagementService.createEmployeeFromUser(values);
      this.refreshSelectedUser(id);
    } finally {
      this.isLoading = false;
    }
  }

  async createUserCard(id: string, values?: any) {
    this.isLoading = true;
    try {
      await userManagementService.createUserFromEmployee(values);
      this.refreshSelectedUser(id);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteUserCard(e: GetPersonManagementOutput) {
    const isStillExists = await userManagementService.deleteUserCard(e.userId);
    if (isStillExists) {
      this.refreshSelectedUser(e.id);
    } else {
      this.data = this.data.filter((user) => user.id !== e.id);
      this.totalCount -= 1;
    }
  }

  async deleteEmployeeCard(e: GetPersonManagementOutput) {
    const isStillExists = await userManagementService.deleteEmployeeCard(e.employeeId);
    if (isStillExists) {
      this.refreshSelectedUser(e.id);
    } else {
      this.data = this.data.filter((user) => user.id !== e.id);
      this.totalCount -= 1;
    }
  }

  async lockUser(e: GetPersonManagementOutput) {
    await identityService.lockUser({ id: e.userId, lockoutEnd: new Date(2025) });
    this.refreshSelectedUser(e.id);
  }

  async unlockUser(e: GetPersonManagementOutput) {
    await identityService.unlockUser(e.userId);
    this.refreshSelectedUser(e.id);
  }

  async sendPasswordReset(e: GetPersonManagementOutput) {
    await identityService.sendPassowordReset({ email: e.accountEmail, appName: 'React' });
  }

  async readUserRoles(e: GetPersonManagementOutput) {
    this.selectedUser = e;
    this.userRoles = await identityService.getUserRoles(e.userId);
    this.availableRole = await identityService.getAvailableRoles();
  }

  async updateUserRoles() {
    this.isLoading = true;
    if (this.userRoles) {
      await userManagementService.updateUserRoles(
        { roleNames: this.userRoles.map((e) => e.name) },
        this.selectedUser?.userId,
      );
    }
    this.refreshSelectedUser(this.selectedUser!.id);
    this.userRoles = undefined;
    this.availableRole = undefined;
    this.isLoading = false;
  }

  // Refresh the user data in selected row
  private async refreshSelectedUser(id: string) {
    const updatedUser = await personManagementService.getById(id);
    if (this.data) {
      this.data = this.data.map((item) => {
        if (item.id === id) item = updatedUser;
        return item;
      });
    }
  }

  async downloadCertificate() {
    this.isLoading = true;
    try {
      const pdfBlob = await convertHTMLToPDF();
      if (pdfBlob) {
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `Certificate_${this.certificateJson?.studentName}_${this.certificateJson?.courseName}_${this.certificateJson?.dateOfIssuie}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    } finally {
      this.isLoading = false;
    }
  }

  async previewCertificate(id: string) {
    const result = await personManagementService.downloadCerfitifcate(id);
    this.certificatePdf = result;
  }

  async previewCertificateJson(id: string) {
    const result = await personManagementService.getCerfitifcateAsJson(id);
    this.certificateJson = result;
  }

  async downloadCertificateJson(id: string) {
    const result = await personManagementService.getCerfitifcateAsJson(id);
    this.certificateJsonDownload = result;
  }

  async setIntroStatus(employeeIntroType: EmployeeIntroType, introStatus: boolean) {
    this.isLoading = true;

    try {
      await personManagementService.setIntroStatus(employeeIntroType, introStatus);
    } finally {
      this.isLoading = false;
    }
  }

  async getAuthMethods() {
    let result = await authMethodsService.getTypesCombobox();
    result = result.filter((e) => e.grantType !== 3);
    this.authMethods = result;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export async function convertHTMLToPDF() {
  // Create a temporary div to insert the HTML content
  const tempDiv: any = document.querySelector('.certificate-container');

  try {
    const canvas = await html2canvas(tempDiv, { scale: 3 });

    const aspectRatio = canvas.width / canvas.height;
    // Determine the width of the PDF based on a target height of 800px and the content's aspect ratio.
    const targetHeight = 970; // in px
    const targetWidth = targetHeight * aspectRatio;

    // Create a new PDF with the calculated dimensions.
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      // 1.17 - magic number
      format: [tempDiv.offsetWidth * 1.17, tempDiv.offsetHeight * 1.17],
    });

    const imgData = canvas.toDataURL('image/jpeg', 1);

    pdf.addImage(imgData, 'JPEG', 0, 0, targetWidth, targetHeight);

    return pdf.output('blob');
  } catch (error) {
    console.error('Error converting HTML to PDF:', error);
  }
}

export const personManagementStore = new Index();
