export const LmsLocaleKeys = (T: (e: string) => string) => ({
  settings: T('Settings_UI'),
  account: T('Account_UI'),
  accounts: T('Accounts_UI'), /// Plural
  role: T('Role_UI'),
  roles: T('Roles_UI'), // Plural
  dateStart: T('DateStarts_UI'),
  dateEnds: T('DateEnds_UI'),
  actions: T('Actions_UI'), // Plural
  action: T('Action_UI'),
  timeOff: T('TimeOff_UI'),
  timeOffRequests: T('TimeOffRequests_UI'),
  description: T('Description_UI'),
  userID: T('userID_UI'),
  fromDate: T('FromDate_UI'),
  toDate: T('ToDate_UI'),
  course: T('Course_UI'),
  courses: T('Courses_UI'),
  calendar: T('Calendar_UI'),
  events: T('Events_UI'), // Plural
  event: T('Event_UI'),
  reports: T('Reports_UI'), // Plural
  report: T('Report_UI'),
  organisation: T('Organisation_UI'),
  list: T('List_UI'),
  directory: T('Directory_UI'),
  orgchart: T('OrgChart_UI'),
  user: T('User_UI'),
  home: T('Home_UI'),
  dashboard: T('Dashboard_UI'),
  users: T('Users_UI'),
  tenants: T('Tenants_UI'),
  about: T('About_UI'),
  accessLevel: T('AccessLevel_UI'),
  seedData: T('SeedData_UI'),
  company: T('Company_UI'),
  tasks: T('Tasks_UI'),
  task: T('Task_UI'),
  create: T('Create_UI'),
  delete: T('Delete_UI'),
  edit: T('Edit_UI'),
  subcategory: T('Subcategory_UI'),
  news: T('News_UI'),
  article: T('Article_UI'),
  ceo: T('CEO_UI'),
  manager: T('Manager_UI'),
  employee: T('Employee_UI'),
  lesson: T('Lesson_UI'),
  question: T('Question_UI'),
  author: T('Author_UI'),
  confirmRead: T('ConfirmRead_UI'),
  price: T('Price_UI'),
  shop: T('Shop_UI'),
  purchase: T('Purchase_UI'),
  template: T('Template_UI'),
  quiz: T('Quiz_UI'),
  courseTitle: T('CourseTitle_UI'),
  integrations: T('Integrations_UI'),
  enterQuestionText: T('EnterQuestionText_UI'),
  questionOrder: T('QuestionOrder_UI'),
  addQuestion: T('AddQuestion_UI'),
  addBlock: T('AddBlock_UI'),
  lessonTitle: T('LessonTitle_UI'),
  text: T('Text_UI'),
  title: T('Title_UI'),
  balance: T('Balance_UI'),
  color: T('Color_UI'),
  icon: T('Icon_UI'),
  image: T('Image_UI'),
  video: T('Video_UI'),
  process: T('Process_UI'),
  flashcards: T('Flashcards_UI'),
  sorting: T('Sorting_UI'),
  graphic: T('Graphic_UI'),
  continue: T('Continue_UI'),
  library: T('Library_UI'),
  chat: T('Chat_UI'),
  job: T('Job_UI'),
  jobs: T('Jobs_UI'),
  employees: T('Employees_UI'),
  firstName: T('FirstName_UI'),
  lastName: T('LastName_UI'),
  middleName: T('MiddleName_UI'),
  prefferedName: T('PrefferedName_UI'),
  birthDate: T('BirthDate_UI'),
  hireDate: T('HireDate_UI'),
  addContent: T('AddContent_UI'),
  dublicate: T('Dublicate_UI'),
  learningPaths: T('LearningPaths_UI'),
  university: T('University_UI'),
  learn: T('Learn_UI'),
  manage: T('Manage_UI'),
  summary: T('Summary_UI'),
  questions: T('Questions_UI'),
  profile: T('Profile_UI'),
  learners: T('Learners_UI'),
  updated: T('Updated_UI'),
  learningPath: T('LearningPath_UI'),
  enrolled: T('Enrolled_UI'),
  courseCreation: T('CourseCreation_UI'),
  courseManagement: T('CourseManagement_UI'),
  myCourses: T('MyCourses_UI'),
  people: T('People_UI'),
  topics: T('Topics_UI'),
  topic: T('Topic_UI'),
  billing: T('Billing_UI'),
  members: T('Members_UI'),
  member: T('Member_UI'),
  items: T('Items_UI'),
  activity: T('Activity_UI'),
  learnerReports: T('LearnerReports_UI'),
  courseReports: T('CourseReports_UI'),
  learner: T('Learner_UI'),
  date: T('Date_UI'),
  duration: T('Duration_UI'),
  lessons: T('Lessons_UI'),
  hoursSpentLearning: T('HoursSpentLearning_UI'),
  lessonsCompleted: T('LessonsCompleted_UI'),
  addUsers: T('AddUsers_UI'),
  filters: T('Filters_UI'),
  shortName: T('ShortName_UI'),
  importCourse: T('ImportCourse_UI'),
  clickToUpload: T('ClickToUpload_UI'),
  newLearningPath: T('NewLearningPath_UI'),
  submit: T('Submit_UI'),
  courseCategories: T('CourseCategories_UI'),
  status: T('Status_UI'),
  category: T('Category_UI'),
  score: T('Score_UI'),
  rating: T('Rating_UI'),
  noRating: T('NoRatings_UI'),
  students: T('Students_UI'),
  groups: T('Groups_UI'),
  cover: T('Cover_UI'),
  update: T('Update_UI'),
  loading: T('Loading_UI'),
  hide: T('Hide_UI'),
  open: T('Open_UI'),
  startTyping: T('StartTyping_UI'),
  completeLesson: T('CompleteLesson_UI'),
  progress: T('Progress_UI'),
  createGroup: T('CreateGroup_UI'),
  confirm: T('Confirm_UI'),
  addNewStudents: T('AddNewStudents_UI'),
  created: T('Created_UI'),
  showAll: T('ShowAll_UI'),
  universityManagement: T('UniversityManagement_UI'),
  search: T('Search_UI'),
  general: T('General_UI'),
  risks: T('Risks_UI'),
  departments: T('Departments_UI'),
  latestNews: T('LatestNewsAt_UI'),
  newHires: T('NewHires_UI'),
  thereAreNoNewHires: T('ThereAreNoNewHires_UI'),
  celebrations: T('Celebrations_UI'),
  benefits: T('Benefits_UI'),
  quickLinks: T('QuickLinks_UI'),
  whosOut: T('WhosOut_UI'),
  readMode: T('ReadMode_UI'),
  newCategory: T('NewCategory_UI'),
  newSubcategory: T('NewSubcategory_UI'),
  paragraph: T('Paragraph_UI'),
  divider: T('Divider_UI'),
  quote: T('Quote_UI'),
  statement: T('Statement_UI'),
  save: T('Save_UI'),
  selectCourse: T('SelectCourse_UI'),
  selectImage: T('SelectImage_UI'),
  teachers: T('Teachers_UI'),
  personal: T('Personal_UI'),
  career: T('Career_UI'),
  timeoff: T('Timeoff_UI'),
  documents: T('Documents_UI'),
  education: T('Education_UI'),
  assets: T('Assets_UI'),
  notes: T('Notes_UI'),
  emergency: T('Emergency_UI'),
  onboarding: T('Onboarding_UI'),
  offboarding: T('Offboarding_UI'),
  return: T('Return_UI'),
  setToPending: T('SetToPending_UI'),
  setToProgress: T('SetToProgress_UI'),
  view: T('View_UI'),
  lessonPreview: T('LessonPreview_UI'),
  link: T('Link_UI'),
  startCourse: T('StartCourse_UI'),
  conference: T('Conference_UI'),
  answerText: T('AnswerText_UI'),
  addAnswer: T('AddAnswer_UI'),
  homeWork: T('HomeWork_UI'),
  type: T('Type_UI'),
  kind: T('Kind_UI'),
  result: T('Result_UI'),
  approve: T('Approve_UI'),
  reject: T('Reject_UI'),
  areYouSure: T('AreYouSure_UI'),
  cancel: T('Cancel_UI'),
  questionnaire: T('Questionnaire_UI'),
  choice: T('Choice_UI'),
  rate: T('Rate_UI'),
  inProgress: T('InProgress_UI'),
  termsAndConditions: T('TermsAndConditions_UI'),
  lessonIsCompleted: T('LessonIsCompleted_UI'),
  next: T('Next_UI'),
  lessonSettings: T('LessonSettings_UI'),
  courseSettings: T('CourseSettings_UI'),
  lessonPassingType: T('LessonPassingType_UI'),
  needApproval: T('NeedApproval_UI'),
  richText: T('RichText_UI'),
  intro: T('Intro_UI'),
  value: T('Value_UI'),
  webinar: T('Webinar_UI'),
  eventType: T('EventType_UI'),
  length_UI: T('Length_UI'),
  timeStarts: T('TimeStarts_UI'),
  onlineMeeting: T('OnlineMeeting_UI'),
  addLink: T('AddLink_UI'),
  location: T('Location_UI'),
  coach: T('Coach_UI'),
  previousLesson: T('PreviousLesson_UI'),
  attention: T('Attention_UI'),
  questionTextIsEmpty: T('QuestionTextIsEmpty_UI'),
  operationSuccesfull: T('OperationSuccesfull_UI'),
  success: T('Success_UI'),
  fileManager: T('FileManager_UI'),
  lessonType: T('LessonType_UI'),
  saveAndReturn: T('SaveAndReturn_UI'),
  switchView: T('SwitchView_UI'),
  notStarted: T('NotStarted_UI'),
  waitingApproval: T('WaitingApproval_UI'),
  completed: T('Completed_UI'),
  pleaseFillField: T('PleaseFillField_UI'),
  pleaseSelectCategory: T('PleaseSelectCategory_UI'),
  fetchCourses: T('FetchCourses_UI'),
  startLesson: T('StartLesson_UI'),
  lessonIsAlreadyCompleted: T('LessonIsAlreadyCompleted_UI'),
  notProvided: T('NotProvided_UI'),
  enterYourQuestion: T('EnterYourQuestion_UI'),
  enterAnswer: T('EnterAnswer_UI'),
  append: T('Append_UI'),
  multipleAnswers: T('MultipleAnswers_UI'),
  required: T('Required_UI'),
  notRequired: T('NotRequired_UI'),
  longAnswer: T('LongAnswer_UI'),
  upload: T('Upload_UI'),
  enterEvent: T('EnterEvent_UI'),
  newFolder: T('NewFolder_UI'),
  searchForFiles: T('SearchForFiles_UI'),
  createNewFolder: T('CreateNewFolder_UI'),
  filter: T('Filter_UI'),
  changeLayout: T('ChangeLayout_UI'),
  siteFiles: T('SiteFiles_UI'),
  levels: T('Levels_UI'),
  addImage: T('AddImage_UI'),
  changeImage: T('ChangeImage_UI'),
  lessonIsLocked: T('LessonIsLocked_UI'),
  sendForApprove: T('SendForApprove_UI'),
  quizResults: T('QuizResults_UI'),
  takeAgain: T('TakeAgain_UI'),
  quizSettings: T('QuizSettings_UI'),
  questionsMixed: T('QuestionsMixed_UI'),
  passRate: T('PassRate_UI'),
  attempts: T('Attempts_UI'),
  timer: T('Timer_UI'),
  questionCount: T('QuestionCount_UI'),
  myGroups: T('MyGroups_UI'),
  teachersGroups: T('TeachersGroups_UI'),
  videoLink: T('VideoLink_UI'),
  addManager: T('AddManager_UI'),
  addObserver: T('AddObserver_UI'),
  addCurator: T('AddCurator_UI'),
  pleaseSelectUser: T('PleaseSelectUser_UI'),
  addNewManager: T('AddNewManager_UI'),
  currentLessoon: T('CurrentLessoon_UI'),
  lessonPassingSettings: T('LessonPassingSettings_UI'),
  lessonsSettings: T('LessonsSettings_UI'),
  tags: T('Tags_UI'),
  logo: T('Logo_UI'),
  thumbnail: T('Thumbnail_UI'),
  coverImage: T('CoverImage_UI'),
  expertName: T('ExpertName_UI'),
  forum: T('Forum_UI'),
  export: T('Export_UI'),
  pleaseSelect: T('PleaseSelect_UI'),
  changeStatus: T('ChangeStatus_UI'),
  lessonsManagement: T('LessonsManagement_UI'),
  mainTeacher: T('MainTeacher_UI'),
  additionalTeachers: T('AdditionalTeachers_UI'),
  notSelected: T('NotSelected_UI'),
  testResults: T('TestResults_UI'),
  statistics: T('Statistics_UI'),
  groupManagers: T('GroupManagers_UI'),
  quizes: T('Quizes_UI'),
  activities: T('Activities_UI'),
  design: T('Design_UI'),
  details: T('Details_UI'),
  requiredToRead: T('RequiredToRead_UI'),
  noAssignedCourses: T('NoAssignedCourses_UI'),
  youCantProceedFurther: T('YouCantProceedFurther_UI'),
  pleaseWait: T('PleaseWait_UI'),
  recent: T('Recent_UI'),
  course_constructor: T('Course_constructor_UI'),
  hour: T('Hour_UI'),
  openEvent: T('OpenEvent_UI'),
  notSet: T('NotSet_UI'),
  unlimited: T('Unlimited_UI'),
  all: T('All_UI'),
  minutes: T('Minutes_UI'),
  accept: T('Accept_UI'),
  synchronize: T('Synchronize_UI'),
  allCourses: T('AllCourses_UI'),
  available: T('Available_UI'),
  blocks: T('Blocks_UI'),
  calendarSynchronization: T('CalendarSynchronization_UI'),
  texts: T('Texts_UI'),
  extraSettings: T('ExtraSettings_UI'),
  questionnaires: T('Questionnaires_UI'),
  responses: T('Responses_UI'),
  spervisor: T('Supervisor_UI'),
  locations: T('Locations_UI'),
  newRequest: T('NewRequest_UI'),
  templates: T('Templates_UI'),
  allMaterials: T('AllMaterials_UI'),
  corporateCulture: T('CorporateCulture_UI'),
  standards: T('Standards_UI'),
  grantAccess: T('GrantAccess_UI'),
  lastActivity: T('LastActivity_UI'),
  groupSettings: T('GroupSettings_UI'),
  primaryColor: T('PrimaryColor_UI'),
  secondaryColor: T('SecondaryColor_UI'),
  reset: T('Reset_UI'),
  additionalInformation: T('AdditionalInformation_UI'),
  myTasks: T('MyTasks_UI'),
  needActions: T('NeedActions_UI'),
  nps: T('Nps_UI'),
  deadlines: T('Deadlines_UI'),
  add: T('Add_UI'),
  management: T('Management_UI'),
  skills: T('Skills_UI'),
  basic: T('Basic_UI'),
  month: T('Month_UI'),
  lessonCount: T('LessonCount_UI'),
  deadLine: T('DeadLine_UI'),
  assigned: T('Assigned_UI'),
  logo_mini: T('Logo_mini_UI'),
  teacher: T('Teacher_UI'),
  userName: T('Username_UI'),
  fullName: T('Fullname_UI'),
  email: T('Email_UI'),
  yes: T('Yes_UI'),
  no: T('No_UI'),
  isActive: T('IsActive_UI'),
  singleAnswer: T('SingleAnswer_UI'),
  comparison: T('ComparisonUI'),
  textAnswer: T('TextAnswer_UI'),
  none: T('None_UI'),
  registrationDate: T('RegistrationDate_UI'),
  gender: T('Gender_UI'),
  learningCourses: T('LearningCourses_UI'),
  attestation: T('Attestation_UI'),
  groupStatus: T('GroupStatus_UI'),
  contacts: T('Contacts_UI'),
  phone: T('Phone_UI'),
  street: T('Street_UI'),
  city: T('City_UI'),
  country: T('Country_UI'),
  postal: T('Postal_UI'),
  notifications: T('Notifications_UI'),
  unread: T('Unread_UI'),
  opened: T('Opened_UI'),
  back: T('Back_UI'),
  seeAll: T('SeeAll_UI'),
  markAllAsRead: T('MarkAllAsRead_UI'),
  answers: T('Answers_UI'),
  pleaseWriteYourQuestion: T('PleaseWriteYourQuestion_UI'),
  changePassword: T('ChangePassword_UI'),
  newPassword: T('NewPassword_UI'),
  currentPassword: T('CurrentPassword_UI'),
  somethingWentWrong: T('SomethingWentWrong_UI'),
  assignCurator: T('AssignCurator_UI'),
  curatorNotSelected: T('CuratorNotSelected_UI'),
  addTeacher: T('AddTeacher_UI'),
  addNewTeacher: T('AddNewTeacher_UI'),
  addNewObserver: T('AddNewObserver_UI'),
  locked: T('Locked_UI'),
  sendForApproval: T('SendForApproval_UI'),
  lessonRequiresApproval: T('LessonRequiresApproval_UI'),
  student: T('Student_UI'),
  curator: T('Curator_UI'),
  noComment: T('NoComment_UI'),
  notActive: T('NotActive_UI'),
  group: T('Group_UI'),
  correct: T('Correct_UI'),
  answered: T('Answered_UI'),
  approvalDetails: T('ApprovalDetails_UI'),
  notPublished: T('NotPublished_UI'),
  published: T('Published_UI'),
  archived: T('Archived_UI'),
  closed: T('Closed_UI'),
  groupPass: T('GroupPass_UI'),
  greatYouHaveCompletedQuestionnaire: T('GreatYouHaveCompletedQuestionnaire_UI'),
  answer: T('Answer_UI'),
  send: T('Send_UI'),
  comments: T('Comments_UI'),
  withoutComment: T('WithoutComment_UI'),
  markAsQuestion: T('MarkAsQuestion_UI'),
  markAsInteresting: T('MarkAsInteresting_UI'),
  replace: T('Replace_UI'),
  passed: T('Passed_UI'),
  bothDatesNotSelected: T('BothDatesNotSelected_UI'),
  startDateNotSelected: T('StartDateNotSelected_UI'),
  endDateNotSelected: T('EndDateNotSelected_UI'),
  thanksForSelectingDates: T('ThanksForSelectingDates_UI'),
  studentAnswered: T('StudentAnswered_UI'),
  moveUp: T('MoveUp_UI'),
  moveDown: T('MoveDown_UI'),
  textQuestion: T('TextQuestion_UI'),
  searchPeople: T('SearchPeople_UI'),
  addNewMembers: T('AddNewMembers_UI'),
  selectLearnersYouWantToEnroll: T('SelectLearnersYouWantToEnroll_UI'),
  selected: T('Selected_UI'),
  detailedReportWithAllAnswers: T('DetailedReportWithAllAnswers_UI'),
  giveAnotherTry: T('GiveAnotherTry_UI'),
  dateCompleted: T('DateCompleted_UI'),
  certificate: T('Certificate_UI'),
  sentForApproval: T('SentForApproval_UI'),
  daysLeft: T('DaysLeft_UI'),
  receivedApproval: T('ReceivedApproval_UI'),
  editor: T('Editor_UI'),
  classic: T('Classic_UI'),
  addCourse: T('AddCourse_UI'),
  addPost: T('AddPost_UI'),
  addNewCourses: T('AddNewCourses_UI'),
  selectCoursesYouWantToAdd: T('SelectCoursesYouWantToAdd_UI'),
  lessonOpenEditor: T('LessonOpenEditor_UI'),
  questionIsNotFilled: T('QuestionIsNotFilled_UI'),
  file: T('File_UI'),
  lessonSlider: T('LessonSlider_UI'),
  companyNameNotEntered: T('CompanyNameNotEntered_UI'),
  project: T('Project_UI'),
  completion: T('Completion_UI'),
  fillBlank: T('FillBlank_UI'),
  createLearningPath: T('CreateLearningPath_UI'),
  variant: T('Variant_UI'),
  lessonCopy: T('LessonCopy_UI'),
  previous: T('Previous_UI'),
  notFound: T('NotFound_UI'),
  copy: T('Copy_UI'),
  logout: T('Logout_UI'),
  createGroupBasedOnLearningPath: T('CreateGroupBasedOnLearningPath_UI'),
  addToCreatedGroup: T('AddToCreatedGroup_UI'),
  createGroupBasedOnCourse: T('CreateGroupBasedOnCourse_UI'),
  repeatPassword: T('RepeatPassword_UI'),
  download: T('Download_UI'),
  notAnswered: T('NotAnswered_UI'),
  priority: T('Priority_UI'),
  passwordUpdated: T('PasswordUpdated_UI'),
  canLogin: T('CanLogin_UI'),
  sendVerification: T('SendVerification_UI'),
  resetUserPassword: T('ResetUserPassword_UI'),
  uploadData: T('UploadData_UI'),
  downloadSample: T('DownloadSample_UI'),
  createFirstCourse: T('CreateFirstCourse_UI'),
  passwordIsTooShort: T('PasswordIsTooShort_UI'),
  passwordsDontMatch: T('PasswordsDontMatch_UI'),
  paste: T('Paste_UI'),
  confirmed: T('Confirmed_UI'),
  emailConfirmed: T('EmailConfirmed_UI'),
  mobilePhone: T('MobilePhone_UI'),
  otherEmail: T('OtherEmail_UI'),
  maritalStatus: T('MaritalStatus_UI'),
  age: T('Age_UI'),
  address: T('Address_UI'),
  jobDescription: T('JobDescription_UI'),
  verification: T('Verification_UI'),
  editProfile: T('EditProfile_UI'),
  lessonFailed: T('LessonFailed_UI'),
  lessonApproved: T('LessonApproved_UI'),
  certificates: T('Certificates_UI'),
  sendEmailConfirmation: T('SendEmailConfirmation_UI'),
  confirmEmailManually: T('ConfirmEmailManually_UI'),
  verificationRequestSent: T('VerificationRequestSent_UI'),
  lastInviteEmailSentDate: T('LastInviteEmailSentDate_UI'),
  youHaveCreatedCourseDublicate_UI: T('YouHaveCreatedCourseDublicate_UI'),
  grantCertificate: T('GrantCertificate_UI'),
  customAnswer: T('CustomAnswer_UI'),
  groupDeleted: T('GroupDeleted_UI'),
  selectReportType: T('SelectReportType_UI'),
  selectStudent: T('SelectStudent_UI'),
  selectLesson: T('SelectLesson_UI'),
  selectGender: T('SelectGender_UI'),
  biography: T('Biography_UI'),
  single: T('Single_UI'),
  married: T('Married_UI'),
  uploadUsers: T('UploadUsers_UI'),
  uploadJobs: T('UploadJobs_UI'),
  employeeCard: T('EmployeeCard_UI'),
  userExists: T('UserExists_UI'),
  done: T('Done_UI'),
  deleteSelected: T('DeleteSelected_UI'),
  courseStructure: T('CourseStructure_UI'),
  review: T('Review_UI'),
  attachment: T('Attachment_UI'),
  selectAll: T('SelectAll_UI'),
  removeAll: T('RemoveAll_UI'),
  selectedRoleIs: T('SelectedRoleIs_UI'),
  totalLessons: T('TotalLessons_UI'),
  overDeadline: T('OverDeadline_UI'),
  attachFile: T('AttachFile_UI'),
  inputFileUrl: T('InputFileUrl_UI'),
  overdue: T('Overdue_UI'),
  today: T('Today_UI'),
  privacyPolicy: T('PrivacyPolicy_UI'),
  support: T('Support_UI'),
  blog: T('Blog_UI'),
  posts: T('Posts_UI'),
  empty: T('Empty_UI'),
  unpublished: T('Unpublished_UI'),
  datePublished: T('DatePublished_UI'),
  grid: T('Grid_UI'),
  table: T('Table_UI'),
  closestCourses: T('ClosestCourses_UI'),
  featuredPosts: T('FeaturedPosts_UI'),
  youDontHaveAnyCertificatesYet: T('YouDontHaveAnyCertificatesYet_UI'),
  issued: T('Issued_UI'),
  issuedBy: T('IssuedBy_UI'),
  completedCourses: T('CompletedCourses_UI'),
  youHaveNoAssignedPaths: T('YouHaveNoAssignedPaths_UI'),
  currentlyYouHaveNoAssignedQuestionnaires: T('CurrentlyYouHaveNoAssignedQuestionnaires_UI'),
  currentlyYouHaveNoAssignedLearningPaths: T('CurrentlyYouHaveNoAssignedLearningPaths_UI'),
  currentlyYouHaveNoAssignedExams: T('CurrentlyYouHaveNoAssignedExams_UI'),
  currentlyYouHaveCreatedCourses: T('CurrentlyYouHaveCreatedCourses_UI'),
  thereAreNoActiveArticlesYet: T('ThereAreNoActiveArticlesYet_UI'),
  thereAreNoPostsMadeYet: T('ThereAreNoPostsMadeYet_UI'),
  aboutCompany: T('AboutCompany_UI'),
  generalCompletion: T('GeneralCompletion_UI'),
  completedLessons: T('CompletedLessons_UI'),
  lessonsInProcess: T('LessonsInProcess_UI'),
  selectQuestion: T('SelectQuestion_UI'),
  chart: T('Chart_UI'),
  updateAvatar: T('UpdateAvatar_UI'),
  menu: T('Menu_UI'),
  selectRole: T('SelectRole_UI'),
  lessonIsTemporaryLocked: T('LessonIsTemporaryLocked_UI'),
  blocked: T('Blocked_UI'),
  coursesTree: T('CoursesTree_UI'),
  teacherGroups: T('TeacherGroups_UI'),
  curatorGroups: T('CuratorGroups_UI'),
  male: T('Male_UI'),
  female: T('Female_UI'),
  other: T('Other_UI'),
  active: T('Active_UI'),
  inactive: T('Inactive_UI'),
  activeCourses: T('ActiveCourses_UI'),
  helloWelcomeBack: T('HelloWelcomeBack_UI'),
  pleaseEnterYourEmailAndPasswordToAccessTheSystem: T('PleaseEnterYourEmailAndPasswordToAccessTheSystem_UI'),
  orgnaizationName: T('OrgnaizationName_UI'),
  organisationNotSelected: T('OrganisationNotSelected_UI'),
  selectOrganization: T('SelectOrganization_UI'),
  courseHasNoErrorsAndCanBeUserForStudy: T('CourseHasNoErrorsAndCanBeUserForStudy_UI'),
  courseHasMistakes: T('ThereAreMistakesDetetectedPleaseCheckHighlightedLessons_UI'),
  forgotPassword: T('ForgotPassword_UI'),
  more: T('More_UI'),
  rememberMe: T('RememberMe_UI'),
  version: T('Version_UI'),
  publish: T('Publish_UI'),
  versions: T('Versions_UI'),
  editable: T('Editable_UI'),
  newVersion: T('NewVersion_UI'),
  versionTitle: T('VersionTitle_UI'),
  previewVersion: T('PreviewVersion_UI'),
  select: T('Select_UI'),
  comment: T('Comment_UI'),
  digest: T('Digest_UI'),
  ideasAndTasks: T('IdeasAndTasks_UI'),
  ideas: T('Ideas_UI'),
  saved: T('Saved_UI'),
  selectFile: T('SelectFile_UI'),
  folder: T('Folder_UI'),
  idea: T('Idea_UI'),
  pinned: T('Pinned_UI'),
  favorite: T('Favorite_UI'),
  blue: T('Blue_UI'),
  pink: T('Pink_UI'),
  orange: T('Orange_UI'),
  green: T('Green_UI'),
  purple: T('Purple_UI'),
  white: T('White_UI'),
  applyFilters: T('ApplyFilters_UI'),
  apply: T('Apply_UI'),
  checkForUpdates: T('CheckForUpdates_UI'),
  getLatestVersions: T('GetLatestVersions_UI'),
  updateCourseToLatestVersion: T('UpdateCourseToLatestVersion_UI'),
  updateCoursesToLatestVersion: T('UpdateCoursesToLatestVersion_UI'),
  updateToLatestVersions: T('UpdateToLatestVersions_UI'),
  updateVersion: T('UpdateVersion_UI'),
  achievements: T('Achievements_UI'),
  contactInfo: T('ContactInfo_UI'),
  authorisation: T('Authorisation_UI'),
  aboutMe: T('AboutMe_UI'),
  password: T('Password_UI'),
  publicInfo: T('PublicInfo_UI'),
  myInteresets: T('MyInteresets_UI'),
  lastUpdated: T('LastUpdated_UI'),
  read: T('Read_UI'),
  assignments: T('Assignments_UI'),
  assignment: T('Assignment_UI'),
  start: T('Start_UI'),
  enterUrl: T('EnterUrl_UI'),
  newLink: T('NewLink_UI'),
  readTime: T('ReadTime_UI'),
  min: T('Min_UI'),
  meeting: T('Meeting_UI'),
  theCourseIsSuitedFor: T('TheCourseIsSuitedFor_UI'),
  recommended: T('Recommended_UI'),
  freeToLearn: T('FreeToLearn_UI'),
  passingType: T('PassingType_UI'),
  learningPathType: T('LearningPathType_UI'),
  free: T('Free_UI'),
  inOrder: T('InOrder_UI'),
  meetings: T('Meetings_UI'),
  changeDashboard: T('ChangeDashboard_UI'),
  groupTitle: T('GroupTitle_UI'),
  continiousEducation: T('ContiniousEducation_UI'),
  presentation: T('Presentation_UI'),
  mySchedule: T('MySchedule_UI'),
  needScore: T('NeedScore_UI'),
  maxScore: T('MaxScore_UI'),
  rateSettings: T('RateSettings_UI'),
  main: T('Main_UI'),
  projects: T('Projects_UI'),
  curators: T('Curators_UI'),
  control: T('Control_UI'),
  remainingLessons: T('RemainingLessons_UI'),
  onApproval: T('OnApproval_UI'),
  access: T('Access_UI'),
  groupResult: T('GroupResult_UI'),
  groupProgress: T('GroupProgress_UI'),
  maxResult: T('MaxResult_UI'),
  minResult: T('MinResult_UI'),
  timing: T('Timing_UI'),
  learningPathSettings: T('LearningPathSettings_UI'),
  observer: T('Observer_UI'),
  approval: T('Approval_UI'),
  theRatioOfLessonTypes: T('TheRatioOfLessonTypes_UI'),
  regular: T('Regular_UI'),
  estimated: T('Estimated_UI'),
  theSystemOfWeightsForEachCourseInTheCurriculum: T('TheSystemOfWeightsForEachCourseInTheCurriculum_UI'),
  theDurationOfEachCourse: T('TheDurationOfEachCourse_UI'),
  issuanceOfACertificate: T('IssuanceOfACertificate_UI'),
  theResultIsMore: T('TheResultIsMore_UI'),
  updates: T('Updates_UI'),
  newLesson: T('NewLesson_UI'),
  study: T('Study_UI'),
  program: T('Program_UI'),
  recording: T('Recording_UI'),
  linkToWebinar: T('LinkToWebinar_UI'),
  responsible: T('Responsible_UI'),
  meetingSettings: T('MeetingSettings_UI'),
  hideTestResult: T('HideTestResult_UI'),
  accessToDetailedResult: T('AccessToDetailedResult_UI'),
  minimumPassingPercent: T('MinimumPassingPercent_UI'),
  speaker: T('Speaker_UI'),
  mixedAnswers: T('MixedAnswers_UI'),
  withoutScore: T('WithoutScore_UI'),
  standardReport: T('StandartReport_UI'),
  generate: T('Generate_UI'),
  pleaseSelectFields: T('PleaseSelectFields_UI'),
  myQuestionnaires: T('MyQuestionnaires_UI'),
  myAttestations: T('MyAttestations_UI'),
  pin: T('Pin_UI'),
  inPlan: T('InPlan_UI'),
  discuss: T('Discuss_UI'),
  pleaseCompleteLesson: T('PleaseCompleteLesson_UI'),
  dateNotSelected: T('DateNotSelected_UI'),
  timeNotSelected: T('TimeNotSelected_UI'),
  reportProblem: T('ReportProblem_UI'),
  askQuestion: T('AskQuestion_UI'),
  slideshow: T('Slideshow_UI'),
  companyWebsite: T('CompanyWebsite_UI'),
  workload: T('Workload_UI'),
  planning: T('Planning_UI'),
  quarter: T('Quarter_UI'),
  generalProgress: T('GeneralProgress_UI'),
  youHaveNoRecords: T('YouHaveNoRecords_UI'),
  courseCount: T('CourseCount_UI'),
  theMeetingIsHeldInTheFormatOfAnOnlineConference: T('TheMeetingIsHeldInTheFormatOfAnOnlineConference_UI'),
  theMeetingIsHeldInTheFormatOfAPhysicalMeeting: T('TheMeetingIsHeldInTheFormatOfAPhysicalMeeting_UI'),
  enterLogin: T('EnterLogin_UI'),
  enterPassword: T('EnterPassword_UI'),
  passwordRecovery: T('PasswordRecovery_UI'),
  enterTo: T('EnterTo_UI'),
  installTraforyApp: T('InstallTraforyApp_UI'),
  enterOrganization: T('EnterOrganization_UI'),
  trainingForYou: T('TrainingForYou_UI'),
  forgotYourPassword: T('ForgotYourPassword_UI'),
  sendLink: T('SendLink_UI'),
  understand: T('Understand_UI'),
  checkIfTheInformationEnteredIsCorrect: T('CheckIfTheInformationEnteredIsCorrect_UI'),
  history: T('History2_UI'),
  creationDate: T('CreationDate_UI'),
  inDevelopment: T('InDevelopment_UI'),
  selectStudent_action: T('SelectStudent_action_UI'),
  attestations: T('Attestations_UI'),
  locationPlace: T('LocationPlace_UI'),
  tasksForToday: T('TasksForToday_UI'),
  notCompletedTasks: T('NotCompletedTasks_UI'),
  men: T('Men_UI'),
  females: T('Females_UI'),
  refreshData: T('RefreshData_UI'),
  curatorsWorkload: T('CuratorsWorkload_UI'),
  close: T('Close_UI'),
  managers: T('Managers_UI'),
  content: T('Content_UI'),
  annotation: T('Annotation_UI'),
  block: T('Block_UI'),
  courseArchitecture: T('CourseArchitecture_UI'),
  notAccessible: T('NotAccessible_UI'),
  isNew: T('IsNew_UI'),
  meetingsTopic: T('MeetingsTopic_UI'),
  chooseType: T('ChooseType_UI'),
  allowedFileTypes: T('AllowedFileTypes_UI'),
  signatureNR: T('SignatureNR_UI'),
  addCategory: T('AddCategory_UI'),
  architecture: T('Architecture_UI'),
  format: T('Format_UI'),
  enterText: T('EnterText_UI'),
  allTypes: T('AllTypes_UI'),
  addStudents: T('AddStudents_UI'),
  imageSize: T('ImageSize_UI'),
  maxFileSize: T('MaxFileSize_UI'),
  imageFormat: T('ImageFormat_UI'),
  shortCourseInfo: T('ShortCourseInfo_UI'),
  shortLearningProgramInfo: T('ShortLearningProgramInfo_UI'),
  keywordsForSimpleSearch: T('KeywordsForSimpleSearch_UI'),
  courseDurationUponRequirement: T('CourseDurationUponRequirement_UI'),
  authorNameSurnameNickname: T('AuthorNameSurnameNickname_UI'),
  courseTermsPlaceholder: T('CourseTermsPlaceholder_UI'),
  complete: T('Complete_UI'),
  cantEdit: T('CantEdit_UI'),
  imagesForCourseAreUsed: T('ImagesForCourseAreUsed_UI'),
  imagesForLearningProgramAreUsed: T('ImagesForLearningProgramAreUsed_UI'),
  sameSettingsGetBeEditedInGroup: T('SameSettingsGetBeEditedInGroup_UI'),
  categories: T('Categories_UI'),
  allCategories: T('AllCategories_UI'),
  courseCategory: T('CourseCategory_UI'),
  createCourse: T('CreateCourse_UI'),
  fileForDownload: T('FileForDownload_UI'),
  enterLink: T('EnterLink_UI'),
  learningPathTitle: T('LearningPathTitle_UI'),
  learningPathCategory: T('LearningPathCategory_UI'),
  mainSettings: T('MainSettings_UI'),
  observers: T('Observers_UI'),
  theCourseContainsSomeNotProperlyFilledLessonsAreYouSureWantToExport: T('TheCourseContainsSomeNotProperlyFilledLessonsAreYouSureWantToExport_UI'),
  courseDoesNotContainErrorsAndCanBeUsed: T('CourseDoesNotContainErrorsAndCanBeUsed_UI'),
  courseHasErrorsPleaseCheckedMarkedLessons: T('CourseHasErrorsPleaseCheckedMarkedLessons_UI'),
  coursePassing: T('CoursePassing_UI'),
  noCourses: T('NoCourses_UI'),
  workWithStudents: T('WorkWithStudents_UI'),
  tableHistory: T('TableHistory_UI'),
  new: T('New_UI'),
  closeQuestion: T('CloseQuestion_UI'),
  allQuestions: T('AllQuestions_UI'),
  systemError: T('SystemError_UI'),
  header: T('Header_UI'),
  dateIssued: T('DateIssued_UI'),
  answerAsText: T('AnswerAsText_UI'),
  answerAsChoice: T('AnswerAsChoice_UI'),
  answerAsScore: T('AnswerAsScore_UI'),
  answerAsFile: T('AnswerAsFile_UI'),
  oneCorrectAnswer: T('OneCorrectAnswer_UI'),
  multipleCorrectAnswers: T('MultipleCorrectAnswers_UI'),
  trueFalse: T('TrueFalse_UI'),
  numberAnswer: T('NumberAnswer_UI'),
  importLesson: T('ImportLesson_UI'),
  requiredToLearn: T('RequiredToLearn_UI'),
  recommendedToLearn: T('RecommendedToLearn_UI'),
  wouldBeHelpful: T('WouldBeHelpful_UI'),
  addJob: T('AddJob_UI'),
  forWhomIsThisCourse: T('ForWhomIsThisCourse_UI'),
  chooseJob: T('ChooseJob_UI'),
  import: T('Import_UI'),
  structure: T('Structure_UI'),
  systemName: T('SystemName_UI'),
  roleName: T('RoleName_UI'),
  accessGranted: T('AccessGranted_UI'),
  userAccount: T('UserAccount_UI'),
  folderName: T('FolderName_UI'),
  imagesForLogoAndPlatformColors: T('ImagesForLogoAndPlatformColors_UI'),
  systemPreviewInSelectedColors: T('SystemPreviewInSelectedColors_UI'),
  courseName: T('CourseName_UI'),
  courseProgress: T('CourseProgress_UI'),
  courseStatus: T('CourseStatus_UI'),
  unpin: T('Unpin_UI'),
  rename: T('Rename_UI'),
  move: T('Move_UI'),
  generalInformation: T('GeneralInformation_UI'),
  eventSettings: T('EventSettings_UI'),
  participants: T('Participants_UI'),
  eventHistory: T('EventHistory_UI'),
  eventDescription: T('EventDescription_UI'),
  sandbox: T('Sandbox_UI'),
  searchDidNotGiveResults: T('SearchDidNotGiveResults_UI'),
  editName: T('EditName_UI'),
  theAreNoCoursesInThisCategory: T('TheAreNoCoursesInThisCategory_UI'),
  enterTitle: T('EnterTitle_UI'),
  addQuestionAsList: T('AddQuestionAsList_UI'),
  dataBuffer: T('DataBuffer_UI'),
  skip: T('Skip_UI'),
  setup: T('Setup_UI'),
  lessonsSettingsFAQ: T('LessonSettingsFAQ_UI'),
  courseSettingsFAQ: T('CourseSettingsFAQ_UI'),
  meetingsSettingsFAQ: T('MeetingsSettingsFAQ_UI'),
  groupMainSettingsFAQ: T('GroupMainSettingsFAQ_UI'),
  online: T('Online_UI'),
  offline: T('Offline_UI'),
  enterFolderTitle: T('EnterFolderTitle_UI'),
  linkToWebsite: T('LinkToWebsite_UI'),
  enterJob: T('EnterJob_UI'),
  reminders: T('Reminders_UI'),
  theDateOfThe: T('TheDateOfThe_UI'),
  meetingTitle: T('MeetingTitle_UI'),
  groupType: T('GroupType_UI'),
  notFilled: T('NotFilled_UI'),
  thisBlockIsInDevelopment: T('ThisBlockIsInDevelopment_UI'),
  displayName: T('DisplayName'),
  readAll: T('ReadAll_UI'),
  requiredQuestion: T('RequiredQuestion_UI'),
  optionMyAnswer: T('OptionMyAnswer_UI'),
  deleteAll: T('DeleteAll_UI'),
  deleteCourse: T('DeleteCourse_UI'),
  inEnglish: T('InEnglish_UI'),
  preview: T('Preview_UI'),
  activate: T('Activate_UI'),
  timeEnds: T('TimeEnds_UI'),
  scorm: T('Scorm_UI'),
  intro_1: T('Intro_1_UI'),
  intro_1_1: T('Intro_1_1_UI'),
  intro_1_2: T('Intro_1_2_UI'),
  intro_1_3: T('Intro_1_3_UI'),
  intro_2: T('Intro_2_UI'),
  intro_2_1: T('Intro_2_1_UI'),
  intro_2_2: T('Intro_2_2_UI'),
  intro_2_3: T('Intro_2_3_UI'),
  intro_3: T('Intro_3_UI'),
  intro_3_1: T('Intro_3_1_UI'),
  intro_3_2: T('Intro_3_2_UI'),
  intro_3_3: T('Intro_3_3_UI'),
  introCompleted: T('IntroCompleted_UI'),
  moreDetails: T('MoreDetails_UI'),
  categoryName: T('CategoryName_UI'),
  subcategoryName: T('SubcategoryName_UI'),
  total: T('Total_UI'),
  fileSelected: T('FileSelected_UI'),
  learningIsEasy: T('LearningIsEasy_UI'),
  login: T('Login_UI'),
  error: T('Error_UI'),
  tenantIsNotActive: T('TenantIsNotActive_UI'),
  youHaveNoAccess: T('YouHaveNoAccess_UI'),
  selectTeacher: T('SelectTeacher_UI'),
  selectType: T('SelectType_UI'),
  acceptedFileFormats: T('AcceptedFileFormats_UI'),
  groupCoursesHaveBeenUpdated: T('GroupCoursesHaveBeenUpdated'),
  courseHasBeenActivated: T('CourseHasBeenActivated_UI'),
  newUser: T('NewUser_UI'),
  accessRight: T('AccessRight_UI'),
  markFlagToGrantUserAccess: T('MarkFlagToGrantUserAccess_UI'),
  resetLinkIsExpired: T('ResetLinkIsExpired_UI'),
  setNewPassword: T('SetNewPassword_UI'),
  confirmPassword: T('ConfirmPassword_UI'),
  inApprovalReview: T('InApprovalReview_UI'),
  quizStatus: T('QuizStatus_UI'),
  startAgain: T('StartAgain_UI'),
  toApproval: T('ToApproval_UI'),
  join: T('Join_UI'),
  openLink: T('OpenLink_UI'),
  chatWithCurator: T('ChatWithCurator_UI'),
  pleaseCompleteQuiz: T('PleaseCompleteQuiz_UI'),
  meetingIsNotCompletedYet: T('MeetingIsNotCompletedYet_UI'),
  pleaseStartLessonFirst: T('PleaseStartLessonFirst_UI'),
  adminPassword: T('AdminPassword_UI'),
  newUserPassword: T('NewUserPassword_UI'),
  loginName: T('Login_name_UI'),
  days: T('Days_UI'),
  groupsClosing: T('GroupsClosing_UI'),
  homeWorks: T('HomeWorks_UI'),
  quizPassingRequests: T('QuizPassingRequests_UI'),
  uncompletedTasks: T('UncompletedTasks_UI'),
  addUser: T('AddUser_UI'),
  yourAnswer: T('YourAnswer_UI'),
  pleaseCompleteRequiredQuestions: T('PleaseCompleteRequiredQuestions_UI'),
  questionsForLessons: T('QuestionsForLessons_UI'),
  selectGroupManagers_UI: T('SelectGroupManagers_UI'),
  requirementsToPassword: T('RequirementsToPassword_UI'),
  eightOrMoreCharacters: T('EightOrMoreCharacters_UI'),
  atLeastOneNonLetterCharacted: T('AtLeastOneNonLetterCharacted_UI'),
  atLeastOneCapitalLetter: T('AtLeastOneCapitalLetter_UI'),
  atLeastOneLowercaseLetter: T('AtLeastOneLowercaseLetter_UI'),
  subsequence: T('Subsequence_UI'),
  selectionFromLists: T('SelectionFromLists_UI'),
  fillInTheBlanksDrag: T('FillInTheBlanksDrag_UI'),
  matching: T('Matching_UI'),
  collaborants: T('Collaborants_UI'),
  collaborant: T('Collaborant_UI'),
  daysShort: T('DaysShort_UI'),
  analytics: T('Analytics_UI'),
  coursesCreation: T('CoursesCreation_UI'),
  scormFiles: T('ScormFiles_UI'),
  welcomeToTrafory: T('WelcomeToTrafory_UI'),
  enterEmail: T('EnterEmail_UI'),
  knowledgeBase: T('KnowledgeBase_UI'),
  postsEditor: T('PostsEditor_UI'),
  reply: T('Reply_UI'),
  imageCentered: T('ImageCentered_UI'),
  imageFullWidth: T('ImageFullWidth_UI'),
  imageText: T('ImageText_UI'),
  textOnImage: T('TextOnImage_UI'),
  fixMistakesAndAfterReuploadTheTemplate: T('FixMistakesAndAfterReuploadTheTemplate_UI'),
  getResults: T('GetResults_UI'),
  resetResults: T('ResetResults_UI'),
  lessonResultWentForApproval: T('LessonResultWentForApproval_UI'),
  formats: T('Formats_UI'),
  sizeUntil: T('SizeUntil_UI'),
  gb: T('Gb_UI'),
  addFileForDownload: T('AddFileForDownload_UI'),
  courseProgramName: T('CourseProgramName_UI'),
  notIssued: T('NotIssued_UI'),
  size: T('Size_UI'),
  errorFilesDuplicated: T('ErrorFilesDuplicated_UI'),
  errorFileSize: T('ErrorFileSize_UI'),
  isDeleteLearningPath: T('IsDeleteLearningPath_UI'),
  show: T('Show_UI'),
  certificateNotIssuesToAllStudentCloseGroup: T('CertificateNotIssuesToAllStudentCloseGroup_UI'),
  studentsNotCompletedEducation: T('StudentsNotCompletedEducation_UI'),
  courseLabel: T('CourseLabel_UI'),
  newPost: T('NewPost_UI'),
  draft: T('Draft_UI'),
  uploadPhoto: T('UploadPhoto_UI'),
  mb: T('Mb_UI'),
  shortDescription: T('ShortDescription_UI'),
  enterAuthor: T('EnterAuthor_UI'),
  enterTags: T('EnterTags_UI'),
  isSaveChanges: T('IsSaveChanges_UI'),
  confirmDeletePostCategory: T('ConfirmDeletePostCategory_UI'),
  dontSave: T('DontSave_UI'),
  allowComments: T('AllowComments_UI'),
  textOfPost: T('TextOfPost_UI'),
  popularTags: T('PopularTags_UI'),
  enterComment: T('EnterComment_UI'),
  isDeleteComment: T('IsDeleteComment_UI'),
  extendEducation: T('ExtendEducation_UI'),
  allPassingResultsWillResetInfo: T('AllPassingResultsWillResetInfo_UI'),
  addSubscriptNotRequired: T('AddSubscriptNotRequired_UI'),
  editorPostsNoData: T('EditorPostsNoData_UI'),
  digestNoData: T('DigestNoData_UI'),
  fillRequiredFields: T('FillRequiredFields_UI'),
  uploadSizeExceededLimit: T('UploadSizeExceededLimit_UI'),
  replies: T('Replies_UI'),
  stepOf: T('StepOf_UI'),
  hints: T('Hints_UI'),
  traforyWelcomeDescription: T('TraforyWelcomeDescription_UI'),
  letsStart: T('LetsStart_UI'),
  invalidUsernameOrPassword: T('InvalidUsernameOrPassword_UI'),
  companyNotFound: T('CompanyNotFound_UI'),
  userAccountTemporallyBlocked: T('UserAccountTemporallyBlocked_UI'),
  meetingsPlan: T('MeetingsPlan_UI'),
  addMeeting: T('AddMeeting_UI'),
  meetingCategory: T('MeetingCategory_UI'),
  basicInformation: T('BasicInformation_UI'),
  meetingStartDate: T('MeetingStartDate_UI'),
  meetingEndDate: T('MeetingEndDate_UI'),
  dateBeforeInquiryDeadline: T('DateBeforeInquiryDeadline_UI'),
  participantCount: T('ParticipantCount_UI'),
  competence: T('Competence_UI'),
  selectDate: T('SelectDate_UI'),
  participation: T('Participation_UI'),
  participationType: T('ParticipationType_UI'),
  meetingDescription: T('MeetingDescription_UI'),
  imageForPreview: T('ImageForPreview_UI'),
  participant: T('Participant_UI'),
  inquiry: T('Inquiry_UI'),
  processDate: T('ProcessDate_UI'),
  participanceInGroup: T('ParticipanceInGroup_UI'),
  rejectionReason: T('RejectionReason_UI'),
  participantCountShort: T('ParticipantCountShort_UI'),
  newInquiries: T('NewInquiries_UI'),
  totalInquiries: T('TotalInquiries_UI'),
  deleteData: T('DeleteData_UI'),
  isDeleteData: T('IsDeleteData_UI'),
  readAccessWillBeRemoved: T('ReadAccessWillBeRemoved_UI'),
  readAccessVersionWillBeUpdated: T('ReadAccessVersionWillBeUpdated_UI'),
  enterDescription: T('EnterDescription_UI'),
  enterSpeakerPlaceholder: T('EnterSpeakerPlaceholder_UI'),
  enterMeetingDurationPlaceholder: T('EnterMeetingDurationPlaceholder_UI'),
  toCreateGroupSelectParticipants: T('ToCreateGroupSelectParticipants_UI'),
  approvedInquiries: T('ApprovedInquiries_UI'),
  rejectedInquiries: T('RejectedInquiries_UI'),
  calendarEvents: T('CalendarEvents_UI'),
  rejectInquiry: T('RejectInquiry_UI'),
  acceptInquiry: T('AcceptInquiry_UI'),
  information: T('Information_UI'),
  paid: T('Paid_UI'),
  submitInquiry: T('SubmitInquiry_UI'),
  selectTagAfter: T('SelectTagAfter_UI'),
  visitType: T('VisitType_UI'),
  addParticipant: T('AddParticipant_UI'),
  meetingName: T('MeetingName_UI'),
  intGroupPausedInfo: T('IntGroupPausedInfo_UI'),
  intGroupCancelInfo: T('IntGroupCancelInfo_UI'),
  intGroupCloseInfo: T('IntGroupCloseInfo_UI'),
  inquireRejectPlaceholder: T('InquireRejectPlaceholder_UI'),
  approved: T('Approved_UI'),
  rejected: T('Rejected_UI'),
  inquiryIsSent: T('InquiryIsSent_UI'),
  inquiryAccepted: T('InquiryAccepted_UI'),
  inquiryRejected: T('InquiryRejected_UI'),
  rejectedByManager: T('RejectedByManager_UI'),
  groupCanAddApprovedInquries: T('GroupCanAddApprovedInquries_UI'),
  dateBeforeInquiryDeadlineShort: T('DateBeforeInquiryDeadlineShort_UI'),
  groupFromEventIsNotFormed: T('GroupFromEventIsNotFormed_UI'),
  acceptanceSuspended: T('AcceptanceSuspended_UI'),
  acceptanceClosed: T('AcceptanceClosed_UI'),
  eventCanceled: T('EventCanceled_UI'),
  workWithInquiries: T('WorkWithInquiries_UI'),
  selectGroup: T('SelectGroup_UI'),
  futureEventsInfo: T('FutureEventsInfo_UI'),
  repeatNewPassword: T('RepeatNewPassword_UI'),
  oldPassword: T('OldPassword_UI'),
  selectCategory: T('SelectCategory_UI'),
  notInPassing: T('NotInPassing_UI'),
  passing: T('Passing_UI'),
  coursesForReading: T('CoursesForReading_UI'),
  readingAccess: T('ReadingAccess_UI'),
  courseNotStarted: T('CourseNotStarted_UI'),
  exportedCourses: T('ExportedCourses_UI'),
  importExportCourses: T('ImportExportCourses_UI'),
  courseExportStartInfo: T('CourseExportStartInfo_UI'),
  exportedCoursesNoData: T('ExportedCoursesNoData_UI'),
  coursesRating: T('CoursesRating_UI'),
  rateTheCourse: T('RateTheCourse_UI'),
  toScore: T('ToScore_UI'),
  notEnoughDiskSpace: T('NotEnoughDiskSpace_UI'),
  uploadToPlatform: T('UploadToPlatform_UI'),
  competencesForThisCourseNotAdded: T('CompetencesForThisCourseNotAdded_UI'),
  toAccept: T('ToAccept_UI'),
  dataSaved: T('DataSaved_UI'),
  scormVersionDontAllowResultsDisplaying: T('ScormVersionDontAllowResultsDisplaying_UI'),
  post: T('Post_UI'),
  replacePost: T('ReplacePost_UI'),
  onlyOnePostCanBeSelected: T('OnlyOnePostCanBeSelected_UI'),
  postWasntChoosen: T('PostWasntChoosen_UI'),
  postDeleted: T('PostDeleted_UI'),
  resultIncreased: T('ResultIncreased_UI'),
  resultHasNotChanged: T('ResultHasNotChanged_UI'),
  resultFell: T('ResultFell_UI'),
  nothingToCompareWith: T('NothingToCompareWith_UI'),
  createCourseTestQuiz: T('CreateCourseTestQuiz_UI'),
  assessmentTools: T('AssessmentTools_UI'),
  courseCatalog: T('CourseCatalog_UI'),
  adaptationPrograms: T('AdaptationPrograms_UI'),
  talentManagementAndEvaluation: T('TalentManagementAndEvaluation_UI'),
  myEducation: T('MyEducation_UI'),
  educationalCenter: T('EducationalCenter_UI'),
  selfEducation: T('SelfEducation_UI'),
  nextLesson: T('NextLesson_UI'),
  zeroAnswersChoosen: T('ZeroAnswersChoosen_UI'),
  questionCountDescription: T('QuestionCountDescription_UI'),
  groupChat: T('GroupChat_UI'),
  questionsAboutTheLesson: T('QuestionsAboutTheLesson_UI'),
  mainPage: T('MainPage_UI'),
  changeLevel: T('ChangeLevel_UI'),
  averageResultForCompletedTests: T('AverageResultForCompletedTests_UI'),
  hasEmptyAnswer: T('HasEmptyAnswer_UI'),
  createAdaptationProgram: T('CreateAdaptationProgram_UI'),
  addSpeaker: T('AddSpeaker_UI'),
  exitWithoutSaving: T('ExitWithoutSaving_UI'),
  changeTheScore: T('ChangeTheScore_UI'),
  remindAboutPassing: T('RemindAboutPassing_UI'),
  institutionName: T('InstitutionName_UI'),
  speciality: T('Speciality_UI'),
  certificateNumber: T('CertificateNumber_UI'),
  finishYear: T('FinishYear_UI'),
  aspectRatioLarge: T('RecommendedAspectRatioLarge_UI'),
  aspectRatioMiddle: T('RecommendedAspectRatioMiddle_UI'),
  searchResults: T('SearchResults_UI'),
  pathToFile: T('PathToFile_UI'),
  recommendedAspectRatio: T('RecommendedAspectRatio_UI'),
  viewResult: T('ViewResult_UI'),
  whoPublished: T('WhoPublished_UI'),
});
