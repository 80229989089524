import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Fallback from '../../assets/images/fallback.webp';
import service from '../../services/FIleClientService';
import { GetFileClientOutputDto } from '../../services/FIleClientService/dto';
import mainStyles from '../../customization/customization.module.css';

type Size = 'none' | '32-32' | '100-100' | '300-300' | '500-500' | '1000-1000' | '1500-1500';

export interface IPicture {
  src?: string;
  className?: string;
  onClick?: () => void;
  size?: Size;
  sizeCustom?: string;
  onError?: (e?: any) => void;
  loader?: any;
  isFit?: boolean;
}

// Элемент для отображения картинок, при загрузке мы можем указывать разные контейнеры (пр. файловый менеджер LMS, Core HR...).
// Для загрузки мы указываем только ID и размер, если на беке вызов в первый раз, картинка сжимается и потом дергается из кеша.
const Picture = ({ src, className, sizeCustom, onClick, size, onError, loader, isFit }: IPicture) => {
  const initPictureSrc = useMemo(() => ({
    content: '',
    mimeType: '',
    isExist: false,
    fileName: '',
  }), []);

  const [resSrc, setSrc] = useState<GetFileClientOutputDto>(initPictureSrc);

  const [isError, setError] = useState(false);

  const isResult = useMemo(() => !!(src && resSrc.content), [src, resSrc.content]);

  const isLoaderShown = !!(loader && !isError && !isResult);

  const select = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleError = useCallback((error: any) => {
    console.warn(error);

    setSrc(initPictureSrc);

    if (onError) {
      setError(true);
      onError();
    }
  }, []);

  useEffect(() => {
    try {
      if (!src) return;

      const id = src.replace('media:', '');

      service.image({ id, resize: size ?? sizeCustom ?? 'none' })
        .then((res) => {
          if (!res) return;

          if (!res.content) throw new Error('Attempt to get deleted image');

          setSrc(res);
        })
        .catch((error) => handleError(error));
    } catch (error) {
      handleError(error);
    }
  }, [src]);

  if (isFit) {
    return (
      <div
        onClick={select}
        className={classNames(className, mainStyles.flex_center)}
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        {isLoaderShown && loader}
        {isResult && (
          <img
            loading='lazy'
            onClick={select}
            src={`data:${resSrc.mimeType || 'image/*'};base64,${resSrc.content}`}
            alt={resSrc.fileName}
            className={className}
            style={{ objectFit: 'cover', position: 'absolute' }}
            onError={(e) => (e.currentTarget.src = Fallback)}
          />
        )}
        <img src={Fallback} className={isResult ? mainStyles.invisible : undefined} style={{ height: '100%' }} />
      </div>
    );
  }

  return (
    <>
      {isLoaderShown && loader}
      <img
        loading={isResult ? 'lazy' : undefined}
        onClick={select}
        src={isResult ? `data:${resSrc.mimeType || 'image/*'};base64,${resSrc.content}` : Fallback}
        alt={resSrc.fileName}
        className={className}
        style={{ objectFit: isResult ? 'cover' : undefined, display: isLoaderShown ? 'none' : undefined }}
      />
    </>
  );
};

export default Picture;
