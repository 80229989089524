/* eslint-disable */

class AppConsts {
  static server = 'https://app2.trafory.com';

  // static server = "https://localhost:44363";

  // static server = "https://test.trafory.com";

  static isProd = true;
  static isIntroHidden = false;

}

export default AppConsts;
